import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
// import camelizeKeys from 'app/util/camelizeKeys';

const ALBUM_TAG = 'album';
const ITEM_TAG = 'item';
const GOODY_BAG_TAG = 'goodyBag';
const GOODY_BAG_ITEMS_TAG = 'goodyBagItems';
const GOODY_BAG_REVIEWS_TAG = 'goodyBagReviews';

const assetApi = createApi({
  reducerPath: 'assets',
  baseQuery: fetchBaseQuery({ baseUrl: '/fm/' }),
  endpoints: (builder) => ({
    getAlbums: builder.query({
      providesTags: [ALBUM_TAG],
      query: () => ('seller_albums.json'),
    }),

    getItems: builder.query({
      providesTags: [ITEM_TAG],
      keepUnusedDataFor: 0,
      query: ({ albumId, cursor, imagesOnly }) => {
        let url = `client_api/items?album_id=${albumId}&cursor=${cursor}`;
        if (imagesOnly) {
          url += '&images_only=true';
        }
        return url;
      },
    }),

    getGoodyBagsForAdvisor: builder.query({
      providesTags: [GOODY_BAG_TAG],
      keepUnusedDataFor: 100,
      query: ({ userId, selectedGoodyBag = null, page = 1, perPage = 20 }) => {
        const baseUrl = `client_api/goody_bags?user_id=${userId}&page=${page}&per_page=${perPage}`;

        if (selectedGoodyBag) {
          return `${baseUrl}&selected_goody_bag=${selectedGoodyBag}`;
        }

        return baseUrl;
      },
      // transformResponse: (response) => camelizeKeys(response),
    }),

    getGoodyBagReviews: builder.query({
      providesTags: (result, error, args) => {
        return [{ type: GOODY_BAG_REVIEWS_TAG, id: `goody-bag-${args.goodBagId}` }];
      },
      keepUnusedDataFor: 100,
      query: ({ goodyBagId }) => (`client_api/goody_bags/${goodyBagId}/goody_bag_reviews`),
    }),

    getGoodyBagItems: builder.query({
      providesTags: (result, error, args) => {
        return [{ type: GOODY_BAG_ITEMS_TAG, id: `goody-bag-${args.goodBagId}` }];
      },
      keepUnusedDataFor: 100,
      query: ({ goodyBagId }) => (`client_api/goody_bags/${goodyBagId}/goody_bag_items`),
    }),

    purchaseGoodyBag: builder.mutation({
      query: ({ goodyBagId }) => ({
        url: `client_api/goody_bags/${goodyBagId}/purchase`,
        method: 'POST',
      }),
    }),
  }),
});

export default assetApi;

export const {
  useGetAlbumsQuery,
  useGetItemsQuery,
  useGetGoodyBagsForAdvisorQuery,
  useLazyGetGoodyBagReviewsQuery,
  useLazyGetGoodyBagItemsQuery,
  usePurchaseGoodyBagMutation,
} = assetApi;
