
export const isNotificationApiSupported = () => {
  return 'Notification' in window &&
    'serviceWorker' in navigator &&
    'PushManager' in window;
}

export const currentSystemPushPermissions = () => {
  if (!isNotificationApiSupported()) return 'denied';
  return Notification.permission;
}

// Returns a promise that resolves to a PermissionStatus object which has a `state` attribute
// that can be 'granted', 'denied', or 'prompt'.
export const pushPermissionFromNavigator = () => {
  return navigator.permissions.query({ name: 'notifications' })
}

export const enablePushNotifications = async () => {
  const status = await Notification.requestPermission();
  localStorage.setItem('pn-remind-later', 'never');

  window.location.reload();
};