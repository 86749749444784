import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Box, Divider, Stack, Typography } from '@mui/material';
import Mailcheck from 'mailcheck';
import { FormWrapper, FormTextField, FormCheckbox, FormButton, FormButtonBox, FormTimeZone } from 'app/components/form_components';
import { useGetUserQuery, useUpdateUserMutation } from 'app/api/mainApi';
import LoginFieldContainer from 'app/containers/account_info/LoginFieldContainer';
import useServerErrors from 'app/hooks/form/useServerErrors';

const emailValidation = {
  required: 'An email address is required for your account.',
  pattern: {
    value: /^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/i,
    message: 'This email address is invalid.',
  },
};

const defaultValues = {
  // Set a default empty value
  login: '',
  first_name: '',
  last_name: '',
  email: '',
  // controlled form components require defaultValues to be set
  enable_platphorm_offers: false,
  canonical_iana_timezone: 'America/New_York',
};

const getFieldValues = (record) => ({
  login: record.login,
  first_name: record.first_name,
  last_name: record.last_name,
  email: record.email,
  enable_platphorm_offers: record.enable_platphorm_offers,
  canonical_iana_timezone: record.canonical_iana_timezone,
});

const AccountInfoContainer = () => {
  const recordKey = 'user';
  const query = useGetUserQuery();
  const record = query.data && query.data[recordKey];
  const [update, updateStatus] = useUpdateUserMutation();

  // set up the form and provide some functions
  const form = useForm({
    mode: 'onBlur', // validate fields on blur
    defaultValues,
  });

  // handle server errors
  useServerErrors(updateStatus, recordKey, form);

  // set the initial field values when the query returns
  const [initialValues, setInitialValues] = useState({});
  useEffect(() => {
    if (record) {
      const initialFieldValues = getFieldValues(record);
      setInitialValues(initialFieldValues);
      form.reset(initialFieldValues);
    }
  }, [record, form.reset]);

  const email = form.watch('email');
  const [suggestion, setSuggestion] = useState(null);

  const handleMailcheck = () => {
    const topLevelDomains = [
      'co.jp', 'co.uk', 'com', 'net', 'org', 'info', 'edu', 'gov', 'mil', 'ca'
    ];

    const defaultDomains = [
      'yahoo.com', 'google.com', 'hotmail.com', 'gmail.com', 'me.com', 'aol.com',
      'mac.com', 'live.com', 'comcast.net', 'googlemail.com', 'msn.com',
      'hotmail.co.uk', 'yahoo.co.uk', 'facebook.com', 'verizon.net',
      'sbcglobal.net', 'att.net', 'gmx.com', 'mail.com', 'outlook.com', 'icloud.com'
    ];

    if (email) {
      Mailcheck.run({
        email,
        domains: defaultDomains,
        topLevelDomains: topLevelDomains,
        suggested: (suggestion) => {
          setSuggestion(suggestion.full);
        },
        empty: () => {
          setSuggestion(null);
        }
      });
    }
  };

  const handleSuggestionClick = () => {
    form.setValue('email', suggestion);
    setSuggestion(null);
  };

  const onSubmit = (formData) => {
    update(formData);
  };

  if (query.isLoading || query.isError) {
    return null;
  }

  return (
    <Box sx={{ margin: '0 auto', maxWidth: '550px' }}>
      <FormWrapper form={form}>
        <Stack spacing={3}>
          <Typography variant="subtitle1">Member Information</Typography>
          <LoginFieldContainer initialValue={initialValues.login} required />
          <FormTextField
            label="First Name"
            name="first_name"
          />
          <FormTextField
            label="Last Name"
            name="last_name"
          />
          <FormTextField
            label="Email Address"
            name="email"
            type="email"
            validation={emailValidation}
            onBlur={handleMailcheck}
          />
          {suggestion && (
            <Typography variant="body2" color="error">
              Did you mean
              <a style={{ color: '#06c', textDecoration: 'underline', cursor: 'pointer' }} onClick={handleSuggestionClick}>
                {suggestion}
              </a>
              ?
            </Typography>
          )}
          <FormTimeZone
            name="canonical_iana_timezone"
          />
          <Typography variant="subtitle1">
            Preferences
          </Typography>
          <FormCheckbox
            id="user-enable-platphorm-offers"
            name="enable_platphorm_offers"
            label="Yes! Send me NiteFlirt offers and news."
          />
          <FormButtonBox>
            <FormButton
              text="Save"
              variant="contained"
              // rhf only calls onSubmit if form is valid
              onClick={form.handleSubmit(onSubmit)}
              order={{ xs: 1, sm: 2 }}
            />
            <FormButton
              text="Cancel"
              variant="outlined"
              onClick={() => {
                window.location.href = '/account';
              }}
              order={{ xs: 2, sm: 1 }}
            />
          </FormButtonBox>
        </Stack>
      </FormWrapper>

      <Divider sx={{ my: 4 }} />
      <Box>
        <Typography variant="body3">
          <a href="/account/close">
            Close my NiteFlirt account
          </a>
        </Typography>
      </Box>
    </Box>
  );
};

export default AccountInfoContainer;
