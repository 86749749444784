import React, { useState } from 'react';
import Tooltip from 'react-tooltip-lite';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useUpdateShowMemberSinceMutation } from 'app/api/mainApi';
import { debounce } from 'lodash';

import { featureFlag } from 'app/util/featureFlag';

import withTooltip from 'app/hoc/withTooltip';
import RecordNameDrawer from './modals/RecordNameDrawer';

const ProfileMenu = ({
  advisor,
  currentUser,
  toggleTip,
  tipOpen,
  setButtonRef,
  setTipContentRef,
  darkAssets,
}) => {
  const anonymousUser = !currentUser;
  const loggedInVisitor = currentUser;
  const advisorIsVisitor = advisor?.id === currentUser?.id;
  const userHasListing = currentUser?.record_name_path;
  const [copyLinkText, setCopyLinkText] = useState('Copy Link');
  const [updateShowMemberSince] = useUpdateShowMemberSinceMutation();
  const isRecordNameEnabled = featureFlag.enabled('RECORD_NAME_19521');
  const isRedesignUsersEnabled = featureFlag.enabled('redesign_users');

  const onProfile = window.location.pathname.toLowerCase().startsWith(`/profile/${advisor?.login}`.toLowerCase());
  const onStorefront = window.location.pathname.toLowerCase().startsWith(`/${advisor?.login}`.toLowerCase());

  // Drawer Management
  const drawerAnchor = 'right';
  const [isRecordNameDrawerOpen, setIsRecordNameDrawerOpen] = useState(false);

  const ignoreCloseDrawerEvents = (event) => {
    return event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift');
  };

  const openRecordNameDrawer = () => {
    setIsRecordNameDrawerOpen(true);
  };

  const closeRecordNameDrawer = (event) => {
    if (ignoreCloseDrawerEvents(event)) return;

    setIsRecordNameDrawerOpen(false);
  };

  const handleCopyLinkClick = () => {
    navigator.clipboard.writeText(window.location.href);
    setCopyLinkText('Copied!');

    setTimeout(() => setCopyLinkText('Copy Link'), 1000);
  };

  const handleShareClick = () => navigator.share({
    url: window.location.href,
    text: 'Check out this Flirt on NF+: ',
  });

  const handleToggleMemberSince = () => {
    updateShowMemberSince({ advisorLogin: currentUser?.login, toggleValue: !advisor?.show_member_since });
  };

  const memberSinceToggleText = () => {
    const actionVerb = advisor?.show_member_since ? 'Hide' : 'Show';

    return `${actionVerb} Member Since Date`;
  };

  // NOTE: React inline styles appear to not support the filter property, so we have to use a class
  const menuAssetClassName = darkAssets ? 'icon-more-hero--dark' : 'icon-more-hero';

  return (
    <>
      <Tooltip
        content={(
          <div
            ref={(el) => setTipContentRef(el)}
            className="nfp-tooltip"
          >
            <div className="row nowrap-xs">
              <div className="col-xs-11">
                <div className="menu-links">
                  {anonymousUser && (
                    <>
                      <Link
                        to={`/login?return_to=${window.location.pathname}`}
                        title="Send Mail"
                        role="link"
                        reloadDocument
                        tabIndex={-7}
                      >
                        Send Mail
                      </Link>
                      <br />
                    </>
                  )}

                  {loggedInVisitor && !advisorIsVisitor && (
                    <>
                      <Link
                        to={`/messages/compose?message[recipients_list]=${advisor?.login}&return_to=${window.location.pathname}`}
                        title="Send Mail"
                        role="link"
                        reloadDocument
                        tabIndex={-6}
                      >
                        Send Mail
                      </Link>
                      <br />
                    </>
                  )}
                  <a
                    role="link"
                    title={copyLinkText}
                    onClick={handleCopyLinkClick}
                    tabIndex={-2}
                  >
                    {copyLinkText}
                  </a>
                  <br />

                  {!onProfile && (
                    <>
                      <Link
                        to={`/profile/${advisor?.login}`}
                        title="Flirt Profile"
                        rel="noreferrer"
                        tabIndex={-5}
                      >
                        Flirt Profile
                      </Link>
                      <br />
                    </>
                  )}

                  { !onStorefront && isRedesignUsersEnabled && (
                    <>
                      <Link
                        to={`/${advisor?.login}`}
                        title="View Storefront"
                      >
                        View Storefront
                      </Link>
                      <br />
                    </>
                  )}

                  {onStorefront && advisorIsVisitor && (
                    <>
                      <a
                        title={memberSinceToggleText()}
                        role="link"
                        onClick={debounce(handleToggleMemberSince, 500)}
                        tabIndex={-4}
                      >
                        {memberSinceToggleText()}
                      </a>
                      <br />
                    </>
                  )}
                  {advisorIsVisitor && (
                    <>
                      <Link
                        to={isRecordNameEnabled ? '#' : '/account/record_name'}
                        title="Record Member Name"
                        tabIndex={-3}
                        reloadDocument={!isRecordNameEnabled}
                        onClick={isRecordNameEnabled ? openRecordNameDrawer : undefined}
                      >
                        Record Member Name
                      </Link>
                      <br />
                    </>
                  )}
                  {navigator.share && (
                    <>
                      <a
                        title="Share"
                        role="link"
                        onClick={handleShareClick}
                        tabIndex={-1}
                      >
                        Share
                      </a>
                      <br />
                    </>
                  )}
                </div>
              </div>
              <div className="col-xs-1 end-xs">
                <a
                  role="link"
                  tabIndex={0}
                  title="Close Profile Menu"
                  onClick={toggleTip}
                >
                  <img src="/plus_frontend/assets/svg/x-close.svg" className="marginlftminus5" alt="close" />
                </a>
              </div>
            </div>
          </div>
        )}
        isOpen={tipOpen}
        tagName="span"
        direction="down"
        forceDirection
      >
        <a
          role="link"
          tabIndex={0}
          title="Toggle Profile Menu"
          ref={(el) => setButtonRef(el)}
          onClick={toggleTip}
        >
          <img src="/plus_frontend/assets/svg/ico-kebob.svg" alt="More" className={menuAssetClassName} />
        </a>
      </Tooltip>
      {userHasListing && (
        <RecordNameDrawer
          anchor={drawerAnchor}
          isOpen={isRecordNameDrawerOpen}
          closeDrawer={closeRecordNameDrawer}
          currentUser={currentUser}
        />
      )}
    </>
  );
};

ProfileMenu.defaultProps = {
  advisor: null,
  currentUser: null,
  darkAssets: false,
};

ProfileMenu.propTypes = {
  advisor: PropTypes.object,
  toggleTip: PropTypes.func.isRequired,
  tipOpen: PropTypes.bool.isRequired,
  setButtonRef: PropTypes.func.isRequired,
  setTipContentRef: PropTypes.func.isRequired,
  currentUser: PropTypes.object,
  darkAssets: PropTypes.bool,
};

export default withTooltip(ProfileMenu);
