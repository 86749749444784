import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Button, Grid, Stack, TextField, Typography } from '@mui/material';

import {
  useCreatePhoneVerificationMutation,
  useVerifyPhoneMutation,
  useUpdateAvailabilityMutation,
} from 'app/api/mainApi';

function ValidatePhone({
  phoneId,
  setVerifying,
  setSelectedForVerification,
  noCurrentChatPhone,
}) {
  const [code, setCode] = useState('');
  const [message, setMessage] = useState('');
  const [updateCurrentChatPhone] = useUpdateAvailabilityMutation();

  const [
    verifyPhone,
    { data: verifyPhoneData, error: verifyPhoneError },
  ] = useVerifyPhoneMutation();

  const performVerifyPhone = () => {
    if (!code || code.length === 0) return;

    verifyPhone({ phoneId, code });
  };

  const [createPhoneVerification] = useCreatePhoneVerificationMutation();

  const handleTyping = (event) => {
    setCode(event.target.value);

    // if they've started typing, we can drop the error message and stop any timeouts
    if (window.verificationTimeout) clearTimeout(window.verificationTimeout);
    setMessage('');
  };

  const reSend = () => {
    createPhoneVerification({ phoneId });
    setMessage('Sent!');
    setCode('');
    if (window.verificationTimeout) clearTimeout(window.verificationTimeout);
    window.verificationTimeout = setTimeout(() => {
      setMessage('');
    }, 5000);
  };

  // send one right away when we mount
  useEffect(() => {
    createPhoneVerification({ phoneId });

    // remove any timeouts when we unmount
    return () => {
      if (window.verificationTimeout) clearTimeout(window.verificationTimeout);
    };
  }, []);

  useEffect(() => {
    if (verifyPhoneError) {
      setMessage(verifyPhoneError.data?.message);
    } else if (verifyPhoneData) {
      if (noCurrentChatPhone) {
        // turn the chat icon green
        updateCurrentChatPhone({ availability: { phone_id: phoneId }, silent: true });
      }
      // hide self
      setVerifying(false);
    }

    // clear message and code after 5 seconds
    if (window.verificationTimeout) clearTimeout(window.verificationTimeout);
    window.verificationTimeout = setTimeout(() => {
      setMessage('');
      setCode('');
    }, 5000);
  }, [verifyPhoneData, verifyPhoneError]);

  return (
    <Grid test-data-id="validate-phone" container spacing={2}>
      <Grid item xs={12}>
        <Stack direction="row" spacing={2} alignItems="center">
          <TextField
            label="Enter Code"
            variant="outlined"
            value={code}
            onChange={handleTyping}
            error={!!(message && message !== 'Sent!')}
            inputProps={{ maxLength: 4 }}
            size="small"
            sx={{ width: '110px' }}
            type="tel"
          />
          <Button
            variant="contained"
            size="small"
            style={{ borderRadius: '6px', height: '38px', padding: '0em 1em' }}
            onClick={performVerifyPhone}
          >
            Verify
          </Button>
          <Button
            variant="outlined"
            size="small"
            style={{ borderRadius: '6px', height: '38px', padding: '0em 1em' }}
            onClick={() => setSelectedForVerification(false)}
          >
            Cancel
          </Button>
          <Typography variant="body3">
            <a href="#" onClick={reSend}>Resend Code</a>
          </Typography>
        </Stack>
      </Grid>
      <Grid item xs={12} sx={{ mt: -1 }}>
        {message && (
          <Typography variant="body2" color={(verifyPhoneError && (message !== 'Sent!')) ? 'error' : 'success'}>
            { message }
          </Typography>
        )}
      </Grid>
    </Grid>
  );
}

ValidatePhone.propTypes = {
  phoneId: PropTypes.number.isRequired,
  setVerifying: PropTypes.func.isRequired,
  setSelectedForVerification: PropTypes.func.isRequired,
  noCurrentChatPhone: PropTypes.bool.isRequired,
};

export default ValidatePhone;
