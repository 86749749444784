import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Grid,
  Typography,
  Paper,
  Divider,
} from '@mui/material';

import PhoneShape from 'app/shapes/PhoneShape';

import AvailabilityList from 'app/redesign/components/availability/AvailabilityList';
import Phone from 'app/redesign/components/availability/Phone';
import AddPhoneContainer from 'app/redesign/containers/AddPhoneContainer';

const Phones = ({ currentUser, phones }) => {
  const [showAddPhoneNumber, setShowAddPhoneNumber] = useState(false);
  const [selectedForVerification, setSelectedForVerification] = useState(null);
  const noCurrentChatPhone = phones.filter((phone) => phone.is_current_chat_phone).length === 0;

  return (
    <Box test-data-id="phone-numbers">
      <Grid container sx={{ mb: 1, alignItems: 'center', px: { xs: 3, sm: 0 } }}>
        <Grid item xs={6}>
          <Typography variant="h6">
            Phone Numbers
          </Typography>
        </Grid>
        { phones.length < 3 && (
          <Grid item xs={6} className="pull-right">
            <Typography variant="body3">
              <a href="#" onClick={() => setShowAddPhoneNumber(true)}>Add Phone Number</a>
            </Typography>
          </Grid>
        )}
      </Grid>
      { (phones.length > 0 || showAddPhoneNumber) && (
        <Paper sx={{ p: 0 }} variant="outlined">
          <AvailabilityList aria-label="phones">
            {phones.map((phone, index) => (
              <React.Fragment key={phone.secure_number}>
                <Phone
                  phone={phone}
                  lastPhone={phones.length === 1}
                  isSelectedForVerification={selectedForVerification === phone.id}
                  setSelectedForVerification={setSelectedForVerification}
                  noCurrentChatPhone={noCurrentChatPhone}
                />
                { (index !== phones.length - 1) && <Divider /> }
              </React.Fragment>
            ))}
            { phones.length < 3 && showAddPhoneNumber && (
              <>
                { phones.length > 0 && <Divider /> }
                <AddPhoneContainer
                  currentUser={currentUser}
                  phones={phones}
                  setShowAddPhoneNumber={setShowAddPhoneNumber}
                />
              </>
            )}
          </AvailabilityList>
        </Paper>
      )}
    </Box>
  );
};

Phones.defaultProps = {
  currentUser: {},
  phones: [],
};

Phones.propTypes = {
  currentUser: PropTypes.object,
  phones: PropTypes.arrayOf(PhoneShape),
};

export default Phones;
