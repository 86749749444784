import React, { useContext } from 'react';
import { Box, Checkbox, FormControlLabel, Grid, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import MenuFilter from 'app/components/favorite_listings/MenuFilter';
import FavoriteListingsContext from 'app/contexts/FavoriteListingsContext';

const menuItems = {
  1: 'My Order',
  2: 'Recently Contacted Available',
  3: 'Most Recently Contacted',
  4: 'Date Added',
  5: 'Name (A-Z)',
};

const CustomBox = ({ highlight, children }) => {
  const theme = useTheme();

  return (
    <Box test-data-id="favorite-listings-controls-header"
      sx={{
        padding: '3px 20px',
        backgroundColor: highlight ? '#e0ecf9' : 'var(--body_background)',
        zIndex: 1,
        position: 'sticky',
        top: { xs: '43px', sm: '45px' },
        borderBottom: '1px solid rgba(240, 240, 240, 1)',
        [theme.breakpoints.up('md')]: {
          paddingRight: theme.spacing(18),
        },
      }}
    >
      {children}
    </Box>
  );
};

const MasterCheckbox = ({ menuLabel }) => {
  const { selectedListings, setSelectedListings, favoriteListingsData } = useContext(FavoriteListingsContext);
  const { favorite_listings: favoriteListings } = favoriteListingsData;
  const favoriteListingsCount = favoriteListings.length;
  const selectedListingsCount = Object.keys(selectedListings).filter(key => selectedListings[key]).length;
  const masterChecked = selectedListingsCount === favoriteListingsCount && favoriteListingsCount > 0;
  const indeterminate = selectedListingsCount > 0 && selectedListingsCount < favoriteListingsCount;

  const handleMasterCheckboxChange = (event) => {
    const newCheckedState = event.target.checked;
    const newSelectedListings = favoriteListings.reduce((acc, listing) => ({
      ...acc,
      [listing.listing_id]: newCheckedState,
    }), {});
    setSelectedListings(newSelectedListings);
  };

  return (
    <FormControlLabel test-data-id="master-checkbox"
      control={(
        <Checkbox
          checked={masterChecked}
          indeterminate={indeterminate}
          onChange={handleMasterCheckboxChange}
        />
      )}
      label={(
        <Typography
          variant="body1"
          fontWeight="bold"
        >
          {menuLabel}
        </Typography>
      )}
    />
  );
};

const RemoveItemsControl = () => {
  const { removeFavoriteListings, selectedListings, setSelectedListings } = useContext(FavoriteListingsContext);
  const selectedIds = Object.keys(selectedListings).filter(key => selectedListings[key]).map(id => parseInt(id));
  const selectedListingsCount = selectedIds.length;

  const deleteFavorites = () => {
    removeFavoriteListings({ listings: selectedIds });
    setSelectedListings({});
  };

  return (
    <Stack test-data-id="remove-item-control" spacing={1} direction="row">
      {selectedListingsCount > 0 && (
        <>
          <Typography test-data-id="selected-listings-count" variant="body1">
            {selectedListingsCount}
            &nbsp;
            {selectedListingsCount === 1 ? 'item' : 'items'}
            &nbsp;selected
          </Typography>
          <Typography variant="body1">|</Typography>
          <Typography variant="body1">
            <a href="#" onClick={deleteFavorites}>Remove</a>
          </Typography>
        </>
      )}
    </Stack>
  );
};
const FavoriteListingsControlsHeader = () => {
  const { selectedListings, sort, handleSortChange } = useContext(FavoriteListingsContext);
  const selectedListingsCount = Object.keys(selectedListings).filter(key => selectedListings[key]).length;

  return (
    <CustomBox highlight={selectedListingsCount > 0}>
      <Grid container style={{ maxWidth: '840px' }}>
        <Grid item xs={11}>
          <Grid container alignItems="center">
            <Grid item sm="auto" xs={12}>
              <MasterCheckbox menuLabel={menuItems[sort]} />
            </Grid>
            <Grid item sm="auto" xs={12} paddingLeft={{ xs: '28px', sm: '0px' }}>
              <RemoveItemsControl />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'flex-end', paddingTop: '8px' }}>
          <MenuFilter onSelect={handleSortChange} menuItems={menuItems} />
        </Grid>
      </Grid>
    </CustomBox>
  );
};

export default FavoriteListingsControlsHeader;
