import React, { useState, useEffect } from 'react';
import { Stack } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useFormContext } from 'react-hook-form';
import { FormTextField } from 'app/components/form_components';
import PasswordMeter from 'app/components/account_info/PasswordMeter';
import { PasswordHelpText } from 'app/components/account_info/AccountInfoTexts';

const PasswordFields = () => {
  const form = useFormContext();
  const [showPassword, setShowPassword] = useState(false);
  const [isPasswordFocused, setIsPasswordFocused] = useState(false);
  const [hasFocusedPasswordConfirmation, setHasFocusedPasswordConfirmation] = useState(false);

  const showPasswordToggle = {
    endAdornment: (
      <InputAdornment position="end">
        <IconButton onClick={() => setShowPassword(!showPassword)} size="small">
          {showPassword ? <VisibilityOff /> : <Visibility />}
        </IconButton>
      </InputAdornment>
    ),
  };

  const password = form.watch('password');
  const passwordConfirmation = form.watch('password_confirmation');

  const getPasswordError = () => {
    if (!password) {
      return 'Please enter a password that you will use to sign in.';
    } else if (password.length < 6 || password.length > 16) {
      return 'Your password must be between 6 and 16 characters.';
    } else if (!/[A-Za-z]/.test(password) || !/[0-9]/.test(password)) {
      return 'Your password must be between 6 and 16 characters.';
    } else {
      return null;
    }
  };

  const getPasswordConfirmationError = () => {
    if (!passwordConfirmation) {
      return 'Please re-type your password.';
    } else if (password && password !== passwordConfirmation) {
      return 'Passwords do not match';
    } else {
      return null;
    }
  };

  const updatePasswordConfirmationError = () => {
    if (!hasFocusedPasswordConfirmation) return;

    const error = getPasswordConfirmationError();
    if (error) {
      form.setError('password_confirmation', {
        type: 'manual',
        message: error,
      });
    } else {
      form.clearErrors('password_confirmation');
    }
  };

  useEffect(() => {
    updatePasswordConfirmationError();
  }, [password, passwordConfirmation]);

  const handleBlur = () => {
    setTimeout(() => {
      setIsPasswordFocused(false);
    }, 0);
  };

  return (
    <Stack
      spacing={1}
      mb={3}
      sx={{ width: '100%', p: 0 }}
      onFocus={() => setIsPasswordFocused(true)}
      onBlur={handleBlur} // Delay blur handling
    >
      <FormTextField
        label="Enter new password"
        name="password"
        type={showPassword ? 'text' : 'password'}
        InputProps={showPasswordToggle}
        validation={{ validate: getPasswordError }}
      >
        <PasswordMeter password={password || ''} />
      </FormTextField>
      {isPasswordFocused && <PasswordHelpText />}
      <FormTextField
        label="Retype Password"
        name="password_confirmation"
        type={showPassword ? 'text' : 'password'}
        InputProps={showPasswordToggle}
        onFocus={() => setHasFocusedPasswordConfirmation(true)}
        onBlur={updatePasswordConfirmationError}
        validation={{ validate: getPasswordConfirmationError }}
      />
    </Stack>
  );
};

export default PasswordFields;
