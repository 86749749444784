import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';

import StorefrontBackArrow from 'app/components/storefront/StorefrontBackArrow';
import ProfileMenu from 'app/redesign/components/ProfileMenu';
import KebobMenuContainer from 'app/containers/storefront/KebobMenuContainer';
import IconLink from 'app/components/shared/IconLink';
import AuthenticatedLink from 'app/components/shared/AuthenticatedLink';

import { featureFlag } from 'app/util/featureFlag';
import useFlirtActions from 'app/hooks/useFlirtActions';
import { contextAwareChatUrl, feedsUrlForUser } from 'app/helpers/userHelper';

const StickyProfilePageControls = ({
  advisor,
  currentUser,
  darkAssets,
  stickyNavVisible,
}) => {
  const { onTributeButtonClick } = useFlirtActions({ advisor });
  const feedsUrl = feedsUrlForUser(advisor);
  const chatUrl = contextAwareChatUrl(currentUser, advisor);

  // TODO: Get rid of classes
  return (
    <Box
      className="container-hdr-sticky"
      id="sticky-nav"
      style={{ display: stickyNavVisible ? 'block' : 'none' }}
    >
      <Box className="row middle-xs start-xs nowrap-xs">
        <Box className="col-xs-5">
          <Box sx={{ float: 'left', marginTop: { left: '13px', top: '0', right: '0', bottom: '10px' } }}>
            <StorefrontBackArrow darkAssets={darkAssets} />
          </Box>
          <h2>{advisor?.login}</h2>
        </Box>
        <Box className="col-xs-7 end-xs padding0">
          <Box className="container-fluid container-hdr-sticky-icons">
            <Box className="row nowrap-xs end-xs">
              {advisor?.chat_eligible && (
                <Box>
                  <IconLink
                    title="Chat"
                    href={chatUrl}
                    iconSrc="/plus_frontend/assets/svg/ico-top-chat.svg"
                    altText="Chat"
                    imgClasses="nav-hero-icon"
                  />
                </Box>
              )}
              <Box>
                <AuthenticatedLink
                  role="link"
                  tabIndex={0}
                  title="Pay a Tribute"
                  onClick={onTributeButtonClick}
                >
                  <img
                    src="/plus_frontend/assets/svg/ico-top-tribute.svg"
                    alt="Pay a Tribute"
                    className="nav-hero-icon"
                  />
                </AuthenticatedLink>
              </Box>
              {!featureFlag.enabled('FEED_TO_STOREFRONT_20571') && advisor?.onboarding_complete && (
                <Box>
                  <IconLink
                    title="Feed"
                    href={feedsUrl}
                    iconSrc="/plus_frontend/assets/svg/ico-feed.svg"
                    altText="Feed"
                    role="link"
                    iconStyle={{ filter: 'brightness(0) invert(1)' }}
                    imgClasses="ico-hero-feed"
                  />
                </Box>
              )}
              <Box className="margintop5">
                {featureFlag.enabled('FEED_TO_STOREFRONT_20571') ? (
                  <KebobMenuContainer
                    advisor={advisor}
                    currentUser={currentUser}
                    darkAssets={darkAssets}
                  />
                ) : (
                  <ProfileMenu advisor={advisor} currentUser={currentUser} />
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

StickyProfilePageControls.defaultProps = {
  advisor: null,
  currentUser: null,
  darkAssets: false,
  stickyNavVisible: false,
};

StickyProfilePageControls.propTypes = {
  advisor: PropTypes.object,
  currentUser: PropTypes.object,
  darkAssets: PropTypes.bool,
  stickyNavVisible: PropTypes.bool,
};

export default StickyProfilePageControls;
