import React, { useEffect } from 'react';
import { PropTypes } from 'prop-types';

import { toCurrency } from 'app/helpers/currencyHelpers';
import { usePurchaseGoodyBagMutation } from 'app/api/assetApi';
import BuyGoodyBagSuccessModal from './BuyGoodyBagSuccessModal';
import AskToAddMoneyModal from './AskToAddMoneyModal';
import { GOODY_BAG_BLOCKED_ERROR, GOODY_BAG_FUNDS_ERROR } from 'app/middleware/clientErrors';
import GoodyBagPurchaseBlocked from './GoodyBagPurchaseBlocked';
import { Typography } from '@mui/material';

const BuyGoodyBagModal = ({
  goodyBag,
  advisor,
  currentUser,
  openModal,
  closeModal,
  availableBalance,
  onAddToBalanceSuccess,
}) => {
  const [purchaseGoodyBag, purchaseGoodyBagResult] = usePurchaseGoodyBagMutation();

  const onPurchaseGoodyBagClick = () => {
    if (!purchaseGoodyBagResult?.isLoading) {
      purchaseGoodyBag({ goodyBagId: goodyBag.id });
    }
  };

  const handlePurchaseGoodyBagSuccess = (purchasedGoodyBagId) => {
    closeModal();
    openModal({
      component: BuyGoodyBagSuccessModal,
      props: {
        purchasedGoodyBagId,
        closeModal,
      },
    });
  };

  const handlePurchaseGoodyBagError = (errorCode) => {
    closeModal();
    if (errorCode === GOODY_BAG_FUNDS_ERROR) {
      openModal({
        component: AskToAddMoneyModal,
        props: {
          openModal,
          closeModal,
          goodyBag,
          currentUser,
          availableBalance,
          onAddToBalanceSuccess,
        },
      });
    }

    if (errorCode === GOODY_BAG_BLOCKED_ERROR) {
      openModal({
        component: GoodyBagPurchaseBlocked,
        props: { closeModal },
      });
    }
  };

  useEffect(() => {
    if (purchaseGoodyBagResult?.isSuccess) {
      handlePurchaseGoodyBagSuccess(purchaseGoodyBagResult.data.purchased_goody_bag_id);
    }

    if (purchaseGoodyBagResult?.error?.status === 403) {
      handlePurchaseGoodyBagError(purchaseGoodyBagResult?.error?.data?.code);
    }
  }, [purchaseGoodyBagResult]);

  return (
    <div>
      <div className="modal-header">
        <h4>Purchase Confirmation</h4>
      </div>

      <div className="modal-body">
        <div className="row" style={{ margin: '20px 0px' }}>
          <div className="col-4" style={{ padding: '0px 20px' }}>
            <img src={goodyBag.thumbnail_url} style={{ width: 'auto', maxWidth: '100px', maxHeight: '100px' }} alt={goodyBag.title} />
          </div>
          <div className="col-8">
            <Typography variant="h6">{goodyBag.title}</Typography>
            <Typography>
              by&nbsp;
              {advisor.login}
            </Typography>
          </div>
        </div>
        <div>
          {toCurrency(goodyBag.price)}
          &nbsp;will be deducted from your account.
          <br />
          View or download purchased items in your File Player.
        </div>
      </div>
      <div className="modal-footer">
        <a href="#" className="btn secondary btn-cancel" onClick={closeModal}>Cancel</a>
        <button
          type="button"
          className="btn"
          onClick={onPurchaseGoodyBagClick}
          disabled={purchaseGoodyBagResult?.isLoading}
        >
          Confirm
        </button>
      </div>
    </div>
  );
};

BuyGoodyBagModal.defaultProps = {
  currentUser: {},
};

BuyGoodyBagModal.propTypes = {
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  goodyBag: PropTypes.object.isRequired,
  advisor: PropTypes.object.isRequired,
  currentUser: PropTypes.object,
  availableBalance: PropTypes.number.isRequired,
  onAddToBalanceSuccess: PropTypes.func.isRequired,
};

export default BuyGoodyBagModal;
