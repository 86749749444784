import React from 'react';
import PropTypes from 'prop-types';

import Drawer from '@mui/material/Drawer';
import Grid from '@mui/material/Grid';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Slider from 'react-slick';

import GoodyBagShape from 'app/shapes/GoodyBagShape';
import GoodyBagItemShape from 'app/shapes/GoodyBagItemShape';

import GoodyBagMeta from 'app/redesign/components/goodyBagDetailsDrawer/GoodyBagMeta';
import PurchaseGoodyBag from 'app/redesign/components/goodyBagDetailsDrawer/PurchaseGoodyBag';
import GoodyBagDescription from 'app/redesign/components/goodyBagDetailsDrawer/GoodyBagDescription';
import IncludedGoodyBagItems from 'app/redesign/components/goodyBagDetailsDrawer/IncludedGoodBagItems';
import HorizontalLine from '../HorizontalLine';
import ImageMedia from 'app/components/media/ImageMedia';
import VideoMediaPreview from 'app/components/media/VideoMediaPreview';
import AudioMedia from 'app/components/media/AudioMedia';
import GoodyBagCallToAction from 'app/components/media/GoodyBagCallToAction';
import DrawerHeader from '../shared/DrawerHeader';
import ImageNotPublicPreviewable from 'app/components/media/ImageNotPublicPreviewable';

const GoodyBagDetailsDrawer = ({
  goodyBag,
  goodyBagItems,
  previewableItems,
  notPreviewableItemCount,
  anchor,
  isOpen,
  closeDrawer,
  openRatingsDrawer,
  advisor,
  onGoodyBagItemClick,
  pauseAllPlayers,
  onBuyGoodyBagButtonClick,
}) => {
  const getSliderComponent = (item) => {
    switch (item && item.type) {
    case 'image':
      if (item.publicPreviewable) {
        return (
          <ImageMedia
            key={item.id}
            item={item}
            onImageClick={() => { onGoodyBagItemClick(item); }}
          />
        );
      } else {
        return <ImageNotPublicPreviewable />;
      }
    case 'audio':
      return (
        <AudioMedia
          key={item.id}
          item={item}
          onImageClick={() => { onGoodyBagItemClick(item); }}
        />
      );
    case 'video':
      return item.previewUrls.map((url) => (
        <VideoMediaPreview
          key={item.id}
          url={url}
          onImageClick={() => { onGoodyBagItemClick(item); }}
        />
      ));
    default:
      return <GoodyBagCallToAction key={`gb-cta-${goodyBag.id}`} itemCount={notPreviewableItemCount} />;
    }
  };

  // TODO: Can we move these classes into an override? or Component maybe more variants?
  return (
    <Drawer
      anchor={anchor}
      open={isOpen}
      onClose={closeDrawer}
    >
      <Grid
        container
        sx={{
          width: { xs: '100vw' },
          maxWidth: { xs: '100vw', sm: '600px' },
        }}
      >
        <DrawerHeader
          closeDrawer={closeDrawer}
          headerCopy="Goody Details"
        />
        <Grid container className="container-goodies-details">
          <Grid item xs={12} className="container-goodie-item">
            <Slider
              dots={false}
              infinite
              speed={500}
              slidesToShow={1}
              slidesToScroll={1}
              afterChange={() => { pauseAllPlayers(); }}
            >
              { previewableItems.flatMap(getSliderComponent) }
            </Slider>
          </Grid>
          <Grid container px={{ xs: 0.5, sm: 2 }} pt={{ xs: 0.5, sm: 1 }}>
            <GoodyBagMeta advisor={advisor} goodyBag={goodyBag} openRatingsDrawer={openRatingsDrawer} />
            <PurchaseGoodyBag
              goodyBag={goodyBag}
              closeDrawer={closeDrawer}
              advisor={advisor}
              onBuyGoodyBagButtonClick={onBuyGoodyBagButtonClick}
            />
            <GoodyBagDescription description={goodyBag.description} />
          </Grid>
          <HorizontalLine />
          <Grid item xs={12}>
            <IncludedGoodyBagItems
              goodyBag={goodyBag}
              goodyBagItems={goodyBagItems}
              onGoodyBagItemClick={onGoodyBagItemClick}
            />
          </Grid>
          { goodyBag.total_ratings > 0 && (
            <>
              <HorizontalLine />
              <Grid item xs={12}>
                <Grid
                  px={{ xs: 0.5, sm: 2 }}
                  style={{ borderTop: '10px solid var(--color_ddd)', padding: '0 16px', minHeight: '48px' }}
                  columns={2}
                  justifyContent={{ xs: 'space-between' }}
                  alignItems={{ xs: 'center' }}
                  container
                  onClick={openRatingsDrawer}
                >
                  <Grid
                    item
                    role="link"
                    aria-controls={`goody-bag-ratings-${goodyBag.id}-link`}
                    id={`goody-bag-ratings-${goodyBag.id}-header`}
                    className="subhead"
                  >
                    Ratings
                  </Grid>
                  <Grid item>
                    <ExpandMoreIcon style={{ fontSize: '2.25em', fill: 'var(--color_gray_nine)' }} />
                  </Grid>
                </Grid>
              </Grid>
            </>
          ) }
        </Grid>
      </Grid>
    </Drawer>
  );
};

GoodyBagDetailsDrawer.defaultProps = {
  goodyBag: {},
  goodyBagItems: [],
  previewableItems: [],
  advisor: {},
};

GoodyBagDetailsDrawer.propTypes = {
  goodyBag: GoodyBagShape,
  goodyBagItems: PropTypes.arrayOf(GoodyBagItemShape),
  previewableItems: PropTypes.array,
  notPreviewableItemCount: PropTypes.number.isRequired,
  anchor: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeDrawer: PropTypes.func.isRequired,
  openRatingsDrawer: PropTypes.func.isRequired,
  advisor: PropTypes.object,
  onGoodyBagItemClick: PropTypes.func.isRequired,
  pauseAllPlayers: PropTypes.func.isRequired,
  onBuyGoodyBagButtonClick: PropTypes.func.isRequired,
};

export default GoodyBagDetailsDrawer;
