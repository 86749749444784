import React from 'react';
import PropTypes from 'prop-types';
import CallStatusShape from 'app/shapes/CallStatusShape';
import PhoneShape from 'app/shapes/PhoneShape';
import ListingShape from 'app/shapes/ListingShape';

import {
  Box,
  Typography,
} from '@mui/material';

import CallFailed from 'app/components/call/callInProgress/Icons/CallFailed';
import CallNow from 'app/components/call/CallNow';

import SanitizedHTML from 'app/helpers/SanitizedHTML';

const ErrorStep = ({
  callState,
  selectedPhone,
  listing,
  balance,
  isLoading,
  triggerCall,
}) => {
  return (
    <Box textAlign="center">
      <Typography variant="h3">
        <SanitizedHTML html={callState.message} />
      </Typography>
      <Box mt={3} mb={3}>
        <CallFailed />
      </Box>
      <Typography variant="body1" gutterBottom>
        Please try again.
      </Typography>
      <Typography variant="body1" gutterBottom>
        You won&apos;t be charged until the conversation begins.
      </Typography>
      <CallNow
        selectedPhone={selectedPhone}
        listing={listing}
        balance={balance}
        isLoading={isLoading}
        triggerCall={triggerCall}
      />
    </Box>
  );
};

ErrorStep.defaultProps = {
};

ErrorStep.propTypes = {
  callState: CallStatusShape.isRequired,
  selectedPhone: PhoneShape.isRequired,
  listing: ListingShape.isRequired,
  balance: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  triggerCall: PropTypes.func.isRequired,
};

export default ErrorStep;
