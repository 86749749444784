import { DateTime } from 'luxon';
import { useContext } from 'react';

import { useGetAppConfigQuery } from 'app/api/mainApi';
import ModalsContext from 'app/contexts/ModalsContext';
import PostLikesModalContainer from 'app/containers/modals/PostLikesModalContainer';

const usePostUtilities = ({ post }) => {
  const { openModal } = useContext(ModalsContext);
  const { data: { current_user: currentUser } = {} } = useGetAppConfigQuery();

  const redirectToLogin = () => {
    window.location.href = `/login?return_url=${encodeURIComponent(`/feed/${post.account.login.replace(/ /g, '%20')}/${post.id}?fromLogin=true`)}`;
  };

  let isScheduled = false;
  if (post?.scheduled_at) {
    const currentScheduledAt = DateTime.fromISO(
      post.scheduled_at,
      { zone: currentUser?.canonical_iana_timezone || 'utc' },
    );
    isScheduled = currentScheduledAt > DateTime.local().setZone(currentUser?.canonical_iana_timezone || 'utc');
  }

  const locked = !post?.unlocked || isScheduled;

  const navigateToPostComments = (e) => {
    if (locked) return e.preventDefault();

    const encodedLogin = encodeURIComponent(post.account.login.replace(/ /g, '%20'));
    window.location.href = `/feed/${encodedLogin}/${post.id}#comments`;

    return null;
  };

  const navigateToStorefrontPostComments = (e) => {
    if (locked) return e.preventDefault();

    const encodedLogin = encodeURIComponent(post.account.login.replace(/ /g, '%20'));
    window.location.href = `/${encodedLogin}/feed/${post.id}#comments`;

    return null;
  };

  const showPostLikes = () => {
    openModal({
      component: PostLikesModalContainer,
      props: { post },
      modalContentStyles: { maxWidth: '290px' },
    });
  };

  return {
    redirectToLogin,
    navigateToPostComments,
    navigateToStorefrontPostComments,
    locked,
    isScheduled,
    showPostLikes,
  };
};

export default usePostUtilities;
